import { Options, Vue } from "vue-class-component";
import ContentHeader from "@/modules/content-header/content-header.vue";

@Options({
    name: "app-profile",
    components: {
        "app-content-header": ContentHeader,
    },
})
export default class Profile extends Vue {
    private activeTab = "ACTIVITY";

    private setActiveTab(tab: string): void {
        this.activeTab = tab;
    }
}
